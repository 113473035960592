import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticClass:"vertical-sass-sidebar",attrs:{"mobile-breakpoint":1000,"height":"100vh","right":_vm.$vuetify.rtl,"color":"transparent","flat":"","floating":"","app":"","clipped":""},model:{value:(_vm.getThemeMode.verticalSaasSidebarDrawer),callback:function ($$v) {_vm.$set(_vm.getThemeMode, "verticalSaasSidebarDrawer", $$v)},expression:"getThemeMode.verticalSaasSidebarDrawer"}},[_c('vue-perfect-scrollbar',{staticClass:"h-100 rtl-ps-none ps scroll",staticStyle:{"height":"100%"},attrs:{"settings":{suppressScrollX: true, wheelPropagation: false}}},[_c(VList,[_c(VListItem,{staticClass:"pa-0"},[_c('div',{staticClass:"d-flex text-center justify-center flex-column w-full"},[_c(VListItemAvatar,{staticClass:"ma-0",attrs:{"min-width":"50","height":"50","width":"50"}},[_c(VImg,{attrs:{"src":require("@/assets/images/faces/1.jpg")}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-16 mb-1 font-weight-regular"},[_vm._v("Watson Joyce")]),_c(VDivider),_c(VListItemSubtitle,{staticClass:"mt-1"},[_c(VIcon,{staticClass:"text-18 mr-2"},[_vm._v("mdi-card-account-mail-outline")]),_c(VIcon,{staticClass:"text-18 mr-2"},[_vm._v("mdi-inbox-multiple-outline")]),_c(VIcon,{staticClass:"text-18 mr-2"},[_vm._v("mdi-home-edit-outline")])],1)],1)],1)])],1),_c(VList,{attrs:{"rounded":"","dense":""}},[_vm._l((_vm.computedItems),function(item,i){return [(item.children)?_c('base-item-group',{key:("group-" + i),attrs:{"item":item}}):_c('base-item',{key:("item-" + i),attrs:{"item":item}})]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }